<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-dialog
          ref="dialog"
          v-model="modalS"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDateBetween(start)"
              label="Fecha de inicio"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="start"
            color="light-blue darken-1"
            scrollable
            locale="es-ve"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="modalS = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modalS = false"
            >
              <!-- @click="$refs.dialog.save(start)" -->
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-dialog
          ref="dialog"
          v-model="modalE"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDateBetween(end)"
              label="Fecha final"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="end"
            scrollable
            color="light-blue darken-1"
            locale="es-ve"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="modalE = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modalE = false"
            >
              <!-- @click="$refs.dialog.save(end)" -->
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="typeTransactionId"
          label="Tipo de transacción"
          :loading="fetchingTypeTransaction"
          :disabled="fetchingTypeTransaction"
          :items="getTypeTransaction"
          item-text="name"
          item-value="typeTransactionId"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="businessId"
          label="Tienda"
          :loading="fetchingStoreWarehouses"
          :disabled="fetchingStoreWarehouses"
          :items="getStores"
          item-text="name"
          item-value="id"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="warehouseId"
          label="Almacén"
          :loading="fetchingStoreWarehouses"
          :disabled="fetchingStoreWarehouses"
          :items="getStoreWarehouses"
          item-text="name"
          item-value="warehouseId"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="relatedWarehouseId"
          label="Almacén de relación"
          :loading="fetchingStoreWarehouses"
          :disabled="fetchingStoreWarehouses"
          :items="getStoreWarehouses"
          item-text="name"
          item-value="warehouseId"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="approved"
          label="Solicitudes"
          :items="status"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="userId"
          label="Vendedor"
          :loading="fetchingUser"
          :disabled="fetchingUser"
          :items="getUsers"
          item-text="fullName"
          item-value="userId"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="clientId"
          label="Cliente"
          :loading="fecthingClients"
          :disabled="fecthingClients"
          :items="getClients"
          item-text="fullName"
          item-value="clientId"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="productId"
          label="Producto o material"
          :loading="fetchingProducts"
          :disabled="fetchingProducts"
          :items="getProducts"
          item-text="productName"
          item-value="productId"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <v-col cols="2" />
    </v-row>

    <v-row
      v-if="typeTransactionId === TYPE_TRANSACTION.venta_por_concesion"
      justify="center"
    >
      <v-col
        cols="6"
      >
        <v-btn
          color="light-blue darken-1"
          @click="genPdf"
        >
          <v-icon>mdi-pdf-box</v-icon>
          Exportar
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
      >
        <span
          v-if="userId !== ''"
          class="green--text"
          style="font-size: 20px"
        >
          <span
            class="grey--text"
            style="font-size: 20px"
          >
            Comision del vendedor:
          </span>  {{ formatPrice(totalSellerCommission) }}
        </span>
        <span
          v-if="businessId !== '' && userId === ''"
          class="green--text"
          style="font-size: 20px"
        >
          <span
            class="grey--text"
            style="font-size: 20px"
          >
            Comision del gerente de tienda:
          </span>  {{ formatPrice(totalManagerCommission) }}
        </span>
        <h1
          :style="{
            fontSize: '40px'
          }"
        >
          <span class="grey--text">Total de Presupuestos:</span> <span class="green--text">{{ formatPrice(total) }}</span>
          <br>
          <!-- totalSellerCommission -->
        </h1>
      </v-col>
    </v-row>

    <add-concessions v-model="openedAddDialog" />
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import AddConcessions from './AddConcessions.vue'
  import { DateTime } from 'luxon'
  import { TYPE_TRANSACTION } from '../../../../const'
  import { jsPDF as JsPdf } from 'jspdf'

  export default {
    name: 'ReportsTableTop',

    components: {
      AddConcessions,
    },

    data () {
      return {
        TYPE_TRANSACTION,
        warehouseId: '',
        start: '',
        end: '',
        relatedWarehouseId: '',
        approved: 'TODAS',
        modalS: false,
        modalE: false,
        openedAddDialog: false,
        status: ['TODAS', 'APROBADAS', 'SIN APROBAR'],
        initialData: true,
        typeTransaction: [],
        fetchingTypeTransaction: false,
        typeTransactionId: '',
        userId: '',
        productId: '',
        clientId: '',
        businessId: '',
      }
    },

    computed: {
      ...mapState([
        'fetchingStoreWarehouses',
        'storeWarehouses',
        'actionWarehouse',
        'fetchingReports',
        'reports',
        'warehouses',
        'fetchingWarehouses',
        'users',
        'fetchingUser',
        'fecthingClients',
        'products',
        'fetchingProducts',
        'clients',
        'businessParentsChilds',
      ]),

      total () {
        let total = 0
        if (this.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion) {
          this.reports.forEach(item => {
            // // // // // // // // // // // // // // // // // // console.log(item.totalAmountCurrencyReference)
            total = total + item.totalAmountCurrencyReference
          })
        }
        return total
      },

      totalSellerCommission () {
        let total = 0
        if (this.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion) {
          this.reports.forEach(item => {
            // // // // // // // // // // // // // // // // // // console.log(item.totalAmountCurrencyReference)
            total = total + item.sellerCommission
          })
        }
        return total
      },

      totalManagerCommission () {
        let total = 0
        if (this.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion) {
          this.reports.forEach(item => {
            // // // // // // // // // // // // // // // // // // console.log(item.totalAmountCurrencyReference)
            total = total + item.managerCommission
          })
        }
        return total
      },

      getStores () {
        return [{ name: 'TODOS', id: '' }, ...this.businessParentsChilds]
      },

      getClients () {
        const usersData = this.clients.map(item => {
          return {
            ...item,
            fullName: item.name + ' ' + item.lastname + ' ' + item.ci,
            clientId: item.id,
          }
        })
        return [{ fullName: 'TODOS', clientId: '' }, ...usersData]
      },

      getStoreWarehouses () {
        const warehouseNew = this.storeWarehouses.map(item => {
          return {
            ...item,
            name: item.business,
          }
        })
        return [{ name: 'TODAS', warehouseId: '' }, ...warehouseNew, ...this.warehouses]
      },

      getWarehouses () {
        return [{ name: 'TODOS', warehouseId: '' }, ...this.warehouses]
      },

      getTypeTransaction () {
        return [{ name: 'TODOS', typeTransactionId: '' }, ...this.typeTransaction]
      },

      getUsers () {
        // // // // // // // // // // // // // // // // console.log('vie')
        // // // // // // // // // // // // // // // // console.log(this.users)
        const usersData = this.users.map(item => {
          return {
            ...item,
            fullName: item.name + ' ' + item.lastName + ' ' + item.ci,
            userId: item.id,
          }
        })
        return [{ fullName: 'TODOS', userId: '' }, ...usersData]
      },

      getProducts () {
        const productsData = this.products.map(item => {
          return {
            ...item,
            productName: item.description + ' ' + item.codigo,
            productId: item.id,
          }
        })
        return [{ productName: 'TODOS', productId: '' }, ...productsData]
      },

      form () {
        return {
          warehouseId: this.warehouseId,
          typeTransactionId: this.typeTransactionId,
          start: this.start,
          end: this.end,
          relatedWarehouseId: this.relatedWarehouseId,
          approved: this.approved === 'TODAS' ? '' : this.approved === 'APROBADAS' ? 1 : 0,
          userId: this.userId,
          productId: this.productId,
          clientId: this.clientId,
          businessId: this.businessId,
        }
      },
    },

    watch: {
      async form (v) {
        if (!this.initialData) {
          // // // // console.log(v)
          await this.fetchReports(v)
        }
      },
    },

    created () {
      this.end = DateTime.fromJSDate(this.nextWeek()).toFormat('yyyy-MM-dd')
      this.start = DateTime.fromJSDate(this.lastWeek()).toFormat('yyyy-MM-dd')
      this.initialData = false
      this.fetchingTypeTransaction = true
      for (const [key, typeTransactionId] of Object.entries(TYPE_TRANSACTION)) {
        this.typeTransaction.push({ typeTransactionId, name: key })
      }
      this.fetchingTypeTransaction = false
    },

    methods: {
      ...mapActions([
        'fetchWarehouseConcessions',
        'fetchReports',
      ]),

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      ...mapMutations({
        changeParams: 'CHANGE_PARAMS',
      }),

      lastWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
      },

      nextWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
      },

      getImageByUrl (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onError = () => reject(
            new Error(`Cannot load image ${url}`),
          )
          img.onload = () => resolve(img)
          img.src = url
        })
      },

      async genPdf () {
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })

        doc.setFontSize(38).text('Sistema Genérico', 10, 23)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18).setTextColor(0, 0, 0).text('Reporte avanzado', 84, 40)

        doc.autoTable({
          columns: [
            { title: 'Codigo', dataKey: 'code' },
            { title: 'Almacén', dataKey: 'warehouse' },
            { title: 'Cliente', dataKey: 'client' },
            { title: 'Observaciones', dataKey: 'description' },
            { title: 'Fecha', dataKey: 'createdAt' },
            { title: 'Total', dataKey: 'totalAmountCurrencyReference' },
          ],
          body: this.reports.map(item => {
            return {
              ...item,
              client: item.clientName + ' ' + item.clientLastName,
              createdAt: this.formatDate(item.createdAt),
              totalAmountCurrencyReference: this.formatPrice(item.totalAmountCurrencyReference),
            }
          }),
          margin: {
            left: 10,
            top: 50,
          },
        })
        doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 30, doc.internal.pageSize.width, doc.internal.pageSize.height - 30)
        // doc.setFontSize(10)
        // doc.text('Observaciones:', 5, doc.internal.pageSize.height - 25)
        // doc.setFontSize(8)
        // doc.text(this.item.description, 10, doc.internal.pageSize.height - 20)
        // doc.setFontSize(15)
        doc.text(`Total: ${this.formatPrice(this.total)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 10)

        doc.autoPrint()
        doc.output('dataurlnewwindow')
        // doc.output('orden')
        // doc.autoPrint()
        // This is a key for printing

        // doc.save('nota-de-entrada.pdf')
      },

      formatDateBetween (dateProp) {
        return DateTime
          .fromFormat(dateProp, 'yyyy-MM-dd')
          .toFormat('dd/MM/yyyy')
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },
    },

  }
</script>
