<template>
  <div>
    <!-- <pre>{{ item }}</pre> -->
    <!-- <v-tooltip
      v-if="!item.approved"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingWarehouseConcessions"
          color="green"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="modalA = true"
        >
          <v-icon>
            mdi-check-all
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para aprobar la solicitud de concesion</span>
    </v-tooltip> -->

    <!-- <v-tooltip
      v-if="!item.approved"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingWarehouseConcessions"
          color="red"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="modalE = true"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para eliminar la solicitud de concesion</span>
    </v-tooltip> -->

    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="light-blue darken-1"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="openedEditDialog = true"
        >
          <v-icon size="25">
            mdi-magnify-plus-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para ver el detalle</span>
    </v-tooltip>

    <!-- <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn> -->
    <concessions-detail-dialog
      :id="item"
      v-model="openedEditDialog"
    />

    <!-- Confirm approve -->
    <v-dialog
      v-model="modalA"
      max-width="510"
    >
      <v-card>
        <v-card-title class="text-h2">
          ¿Seguro de querer Confirmar esta solicitud?
        </v-card-title>

        <v-card-text class="text-center grey--text">
          Esta accion creara un registro permanente en la aplicación, ¿Desea continuar?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="modalA = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :loading="fetchingWarehouseConcessions"
            @click="approve"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm delete -->

    <v-dialog
      v-model="modalE"
      max-width="480"
    >
      <v-card>
        <v-card-title class="text-h2">
          ¿Seguro de querer Eliminar esta solicitud?
        </v-card-title>

        <v-card-text class="text-center grey--text">
          Esta accion creara un registro permanente en la aplicación, ¿Desea continuar?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="modalE = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :loading="fetchingWarehouseConcessions"
            @click="deleteTransaction"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import ConcessionsDetailDialog from './ConcessionsDetailDialog.vue'
  import { mapState, mapActions } from 'vuex'
  import { TYPE_TRANSACTION } from '../../../../const'

  export default {
    name: 'ConcessionsActions',
    components: {
      ConcessionsDetailDialog,
    },
    props: {
      item: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        modalA: false,
        modalE: false,
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingWarehouseConcessions',
        'user',
        'actionsParentBusiness',
        'searchParamsConcessions',
        'actionWarehouse',
      ]),
    },
    methods: {
      ...mapActions([
        'aproveWarhouseConcessionsByCode',
        'deleteWarhouseConcessionsByCode',
        'fetchWarehouseConcessions',
        'fetchWarehouseInventory',
        'aproveWarhouseConcessionsById',
        'deleteWarhouseConcessionsById',
      ]),

      async approve () {
        if (this.item.typeTransactionId === TYPE_TRANSACTION.concesion) {
          // // // // // // // // // // // // // // // // // // // // // // // console.log('Aprueba por Codigo: ', this.item.code)
          const form = {
            code: this.item.code,
            approvedByUserId: this.user.id,
          }
          // // // // // // // // // // // // // // // // // // // // // // // console.log('FORM', form)
          await this.aproveWarhouseConcessionsByCode(form)
        } else if (this.item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion) {
          // // // // // // // // // // // // // // // // // // // // // // // console.log('Aprueba por id: ', this.item.id)
          const form = {
            id: this.item.id,
            approvedByUserId: this.user.id,
          }
          // // // // // // // // // // // // // // // // // // // // // // // console.log('FORM', form)
          await this.aproveWarhouseConcessionsById(form)
        }

        this.modalA = false

        await this.fetchWarehouseConcessions(this.searchParamsConcessions)
        await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      },

      async deleteTransaction () {
        if (this.item.typeTransactionId === TYPE_TRANSACTION.concesion) {
          // // // // // // // // // // // // // // // // // // // // // // // console.log('Aprueba por Codigo: ', this.item.code)
          const form = {
            code: this.item.code,
            approvedByUserId: this.user.id,
          }
          await this.deleteWarhouseConcessionsByCode(form)
        } else if (this.item.typeTransactionId === TYPE_TRANSACTION.venta_por_concesion) {
          // // // // // // // // // // // // // // // // // // // // // // // console.log('Aprueba por id: ', this.item.id)
          const form = {
            id: this.item.id,
            approvedByUserId: this.user.id,
          }
          // // // // // // // // // // // // // // // // // // // // // // // console.log('FORM', form)
          await this.deleteWarhouseConcessionsById(form)
        }

        this.modalE = false

        await this.fetchWarehouseConcessions(this.searchParamsConcessions)
        await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      },
      // async onDeactiveWarehouse () {
      //   await this.deleteProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
      // async onActiveWarehouse () {
      //   await this.activeProducts({ id: this.product.id })
      //   await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
