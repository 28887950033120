<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-certificate-outline"
          title="Reporte avanzado"
          class="px-5 py-3"
          color="orange"
        >
          <reports-table-top />
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-container>
          <v-data-table
            :headers="headers"
            :items="reports"
            :loading="fetchingReports"
            :search="search"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.client`]="{ item: input }">
              <span v-if="input.clientName">
                {{ input.clientName }} {{ input.clientLastName }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>
            <template v-slot:[`item.totalAmountCurrencyReference`]="{ item: input }">
              <span class="green--text">{{ formatPrice(input.totalAmountCurrencyReference) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item: item }">
              <concessions-actions
                :item="item"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import ReportsTableTop from './ReportsTableTop'
  import ConcessionsActions from './ConcessionsActions.vue'

  export default {
    name: 'ReportsTable',
    components: {
      ReportsTableTop,
      ConcessionsActions,
    },
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Codigo',
            value: 'code',
          },
          {
            text: 'Almacén',
            value: 'warehouse',
          },
          {
            text: 'Almacén de relación',
            value: 'relatedWarehouse',
          },
          {
            text: 'Cliente',
            value: 'client',
          },
          {
            text: 'Descripcion',
            value: 'description',
          },
          {
            text: 'Fecha',
            value: 'createdAt',
          },
          {
            text: 'Total',
            value: 'totalAmountCurrencyReference',
          },
          {
            text: 'Ver',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'fetchingWarehouseConcessions',
        'warehousesConcessions',
        'fetchingReports',
        'reports',
        // 'products',
        // 'fetchingProducts',
      ]),

      total () {
        let total = 0
        this.reports.forEach(item => {
          // // // // // // // // // // // // // // // // // // console.log(item.totalAmountCurrencyReference)
          total = total + item.totalAmountCurrencyReference
        })
        return total
      },
    },

    methods: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

  }
</script>
