var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-certificate-outline","title":"Reporte avanzado","color":"orange"}},[_c('reports-table-top'),_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busca por cualquier campo","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"loading":_vm.fetchingReports,"search":_vm.search,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
          var input = ref.item;
return [(input.description)?_c('span',[_vm._v(" "+_vm._s(input.description)+" ")]):_c('span',[_vm._v(" ... ")])]}},{key:"item.client",fn:function(ref){
          var input = ref.item;
return [(input.clientName)?_c('span',[_vm._v(" "+_vm._s(input.clientName)+" "+_vm._s(input.clientLastName)+" ")]):_c('span',[_vm._v(" ... ")])]}},{key:"item.createdAt",fn:function(ref){
          var input = ref.item;
return [_vm._v(" "+_vm._s(new Date(input.createdAt).getDate())+"/"+_vm._s(new Date(input.createdAt).getMonth() + 1)+"/"+_vm._s(new Date(input.createdAt).getFullYear())+" ")]}},{key:"item.totalAmountCurrencyReference",fn:function(ref){
          var input = ref.item;
return [_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.formatPrice(input.totalAmountCurrencyReference)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('concessions-actions',{attrs:{"item":item}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }